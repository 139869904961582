@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('./poppins-v6-latin-regular.woff2') format('woff2'),
    url('./poppins-v6-latin-regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('./poppins-v6-latin-500.woff2') format('woff2'),
    url('./poppins-v6-latin-500.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('./poppins-v6-latin-600.woff2') format('woff2'),
    url('./poppins-v6-latin-600.woff') format('woff');
  font-display: swap;
}
